<template>
  <div class="card">
    <div class="card-title vertical-center">
      笔记简介
    </div>
    <van-field rows="10"  type="textarea" autosize v-model="brief" placeholder="请输入笔记简介" maxlength="100" show-word-limit />
    <div class="uptext"><span style="color:red;">*</span>上传截图</div>
    <van-uploader v-model="fileList" multiple :max-count="1" />
    <div class="sub align-center" @click="sub()">提交</div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "card",
  data() {
    return {
      starnum:3,
      brief:'',
      checked:false,
      fileList:[]
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    sub: _.debounce(function () {
      if (!this.fileList.length){
        this.$toast('请上传一张截图')
        return false
      }
      let data =new FormData()
      data.append('file', this.fileList[0].file)
      data.append('type', 'card')
      this.$http.post(this.$api.imageUpload, data, true).then(res => {
        if (res.data.success) {
          this.upcard(res.data.data.url)
        } else {
          this.$toast('提交失败')
        }
      })

    }, 500),
    upcard(iconurl){
      let url = this.$api.punchClock;
      let data ={
        icon:iconurl,
        brief:this.brief,
        group_id:this.$route.query.id
      }
      this.$http.post(url,data,true).then( res =>{
        if(res.data.success){
          this.$router.replace({path: '/user/group/list', query: {id: this.$route.query.id}})
        }else{
          this.$toast(res.data.message)
        }
      })
    }
  },
  watch: {
    userInfo(val, oldVal) {
      return val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  components: {
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/group/card.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.van-cell{
  padding: unset;
  margin: 30/$r 0 50/$r;
}
.van-field__control{
  border-radius: 10/$r;
  background: #F7F7F7;
  padding: 20/$r;
}
</style>